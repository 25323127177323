<template>
  <div id="tasks-page">
    <app-box width="large">
      <template #legend>Tasks</template>
      <template #title>{{ cItems.length ? (minimal ? 'Active' : "Active tasks") : 'What are you working on?' }}</template>
      <div class="mb-4 md:mb-6" :class="[cItems.length ? ['md:flex md:justify-end'] : []]">
        <p v-if="!cItems.length || !minimal" class="text-sm md:text-base text-gray-700 leading-snug">
          <template v-if="cItems.length">
            Active tasks are the ones you're working on right now, or getting ready to start working on soon.
            As you complete tasks, mark them as such using the checkboxes on the left, they will be archived automatically at midnight.
          </template>
          <template v-else-if="!minimal">
            Looks like there's nothing to work on at the moment.
            Enjoy it and go outside for a bit, or start writing down the tasks you plan on working on.
          </template>
        </p>
        <div :class="[cItems.length ? [minimal ? ['-mt-28 md:-mt-20 text-center'] : ['mt-4 md:mt-0 md:pl-8 md:self-end']] : [minimal ? ['text-center -mt-28 md:-mt-20'] : ['text-center mt-8']]]">
          <app-button v-tooltip="tooltipTextNew" :class="[!minimal ? ['relative md:pl-10'] : []]" type="add" @click="openModal()">
            <svg v-if="!minimal" xmlns="http://www.w3.org/2000/svg" class="hidden md:inline absolute left-4 top-3.5 h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            <span :class="{ 'md:ml-1.5': !minimal}">New task</span>
          </app-button>
        </div>
      </div>
      <app-table v-if="cItems.length" class="-mx-5 md:mx-0" :class="minimal ? ['-mt-1 md:-mt-14'] : []">
        <app-thead>
          <app-tr>
            <app-th colspan="2">
              <div class="flex">
                <div class="flex-grow">
                  {{ cItems.length }} task{{ cItems.length !== 1 ? 's' : '' }} <span class="hidden md:inline ml-0.5 text-gray-500 font-normal">| <span :class="{'text-green-600 font-bold': done && !showDoneTasks}">{{ done }} completed</span> | {{ todo }} to do</span>
                </div>
                <div class="-mr-2">
                  <template v-if="done">
                    <button v-if="showFilterOptions" class="ml-1 text-gray-700 bg-gray-200 font-medium rounded border border-gray-400 hover:border-gray-500 hover:shadow-sm text-2xs pr-1 focus:outline-none focus:border-blue-400" @click="handleDoneTasksToggle"><svg xmlns="http://www.w3.org/2000/svg" class="inline border-r border-gray-400 h-3 w-4 mr-1" :class="{'text-gray-400': showDoneTasks}" viewBox="0 0 20 20" fill="currentColor"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" /></svg>Hide completed</button>
                    <button class="ml-1 text-gray-700 bg-gray-200 font-medium rounded border border-gray-400 hover:border-gray-500 hover:shadow-sm text-2xs px-1 focus:outline-none focus:border-blue-400" @click="handleFilterClick">{{ showFilterOptions ? "Close" : "Filter" }}</button>
                  </template>
                </div>
              </div>
            </app-th>
          </app-tr>
        </app-thead>
        <app-tbody>
          <transition-group :css="animate" :move-class="moveClass" :enter-active-class="enterActiveClass" :enter-from-class="enterFromClass" :enter-to-class="enterToClass" :leave-active-class="leaveActiveClass" :leave-from-class="leaveFromClass" :leave-to-class="leaveToClass">
            <template v-for="item in cItems">
              <tr v-if="!item.done || showDoneTasks" :id="item.lastAdded ? 'last-added' : null" :key="item.uuid">
                <app-td class="relative" :class="{'bg-gradient-to-r from-green-200 to-gray-200 lg:to-transparent': item.done, 'bg-gradient-to-r from-orange-200 to-gray-100 lg:to-transparent': item.orange, 'bg-gradient-to-r from-red-200 to-gray-100 lg:to-transparent': item.red, 'drag-over': dragOver == item.uuid && item.draggable && draggableItemCount > 1, 'cursor-move': item.draggable && draggableItemCount > 1}" :draggable="item.draggable && draggableItemCount > 1" @dragover.prevent="dragOver = item.uuid" @dragleave="dragOver = ''" @dragstart="dragStart(item.uuid, $event)" @drop.prevent="drop(item.uuid, $event)">
                  <div class="flex items-center">
                    <input v-tooltip:[tooltipData(item)]="tooltipTextComplete" type="checkbox" :checked="item.done" class="h-6 w-6 md:h-8 md:w-8 rounded text-green-500 border-gray-400 bg-gray-50 focus:ring-1 focus:ring-blue-400 hover:border-gray-500 hover:shadow" @change="check(item.uuid, $event.target.checked)">
                    <app-td-content class="ml-4">
                      <template #first>
                        <span class="mr-1.5" :class="{'text-green-800': item.done, 'text-orange-800': item.orange, 'text-red-800': item.red}">{{ item.title }}</span>
                        <app-badge v-for="(label, index) in item.labels" :key="index" class="mr-1.5" :color="label.color" :description="label.description || ''">{{ label.name }}</app-badge>
                      </template>
                      <template #second>
                        <span :class="{'text-green-700': item.done, 'text-orange-700': item.orange, 'text-red-700': item.red}">{{ item.projectName }}</span>
                        <template v-if="item.recurringText">
                          <span class="mx-1 text-gray-500" :class="{'text-green-600': item.done, 'text-orange-600': item.orange, 'text-red-600': item.red}">|</span><span class="text-gray-600" :class="{'text-green-700': item.done, 'text-orange-700': item.orange, 'text-red-700': item.red}">{{ item.recurringText }}</span>
                        </template>
                        <template v-else-if="item.doOnText">
                          <span class="mx-1 text-gray-500" :class="{'text-green-600': item.done, 'text-orange-600': item.orange, 'text-red-600': item.red}">|</span><span class="text-gray-600" :class="{'text-green-700': item.done, 'text-orange-700': item.orange, 'text-red-700': item.red}">{{ item.doOnText }}</span>
                        </template>
                        <template v-if="item.link">
                          <span class="mx-1 text-gray-500" :class="{'text-green-600': item.done, 'text-orange-600': item.orange, 'text-red-600': item.red}">|</span><a :href="item.link" target="_blank" class="text-gray-600 underline hover:no-underline" :class="{'text-green-700': item.done, 'text-orange-700': item.orange, 'text-red-700': item.red}">{{ item.linkTitle }}</a>
                        </template>
                      </template>
                    </app-td-content>
                  </div>
                </app-td>
                <app-td class="w-1 lg:bg-transparent" :class="{'bg-gray-200': item.done, 'bg-gray-100': item.orange, 'bg-gray-100': item.red}">
                  <div class="flex justify-end">
                    <div class="hidden md:block mr-2">
                      <app-timer :item="item" />
                    </div>
                    <div class="mr-2">
                      <app-details :item="item" @save="saveDetails(item.uuid, $event)" />
                    </div>
                    <div>
                      <app-dropdown :options="item.options" entity="task" :count-url="`api/entries/count?itemUuid=${item.uuid}`" @click="handleChoice(item, $event)" />
                    </div>
                  </div>
                </app-td>
              </tr>
            </template>
          </transition-group>
        </app-tbody>
      </app-table>
      <div class="text-center text-sm text-gray-600 mt-4">
        <hr v-if="!cItems.length" class="mt-8 pt-4" :class="{'-mt-1 border-transparent': minimal}">
        <router-link to="/backlog" class="underline hover:no-underline">{{ minimal ? 'Backlog' : 'View backlog tasks' }}</router-link>
        <span class="mx-2 text-gray-500">|</span>
        <router-link to="/archive" class="underline hover:no-underline">{{ minimal ? 'Archive' : 'View archived tasks' }}</router-link>
        <span class="mx-2 text-gray-500">|</span>
        <router-link to="/labels" class="underline hover:no-underline">{{ minimal ? 'Labels' : 'Manage labels' }}</router-link>
      </div>
      <transition enter-active-class="transition-opacity duration-500" enter-from-class="opacity-0" enter-to-class="opacity-100" leave-active-class="transition-opacity duration-500" leave-from-class="opacity-100" leave-to-class="opacity-0">
        <app-modal v-if="showModal" @close="closeModal">
          <template #title>
            {{ activeForm == 'labels' ? 'Labels' : (editUuid ? "Edit task" : "Create new task") }}
          </template>
          <template v-if="activeForm == 'labels'">
            <template v-if="cLabels.length">
              <app-form @submit.prevent="submitLabelsForm">
                <app-form-field>
                  <app-label id="label-uuids">
                    Label
                  </app-label>
                  <app-multiple-select id="label-uuids" v-model="form.fields.labelUuids" :options="cLabels" />
                </app-form-field>
                <app-button class="mt-3 transition-colors" :disabled="loading">
                  Update
                </app-button>
              </app-form>
            </template>
            <template v-else>
              <p class="text-sm md:text-base text-gray-700 leading-snug">
                Before you can apply labels to tasks you need to create the labels themselves. This is done on the <router-link to="/labels" class="underline hover:no-underline">manage labels page</router-link> (you can find a link to it at the bottom of the active tasks page as well).
              </p>
            </template>
          </template>
          <template v-else>
            <app-form @submit.prevent="submitForm">
              <app-form-field>
                <app-label id="project-uuid">
                  Project
                </app-label>
                <app-select id="project-uuid" v-model="form.fields.projectUuid" :options="cProjects" />
              </app-form-field>
              <template v-if="showAllFormInputs">
                <div class="flex">
                  <app-form-field class="w-4/12">
                    <app-label id="type">
                      Type
                    </app-label>
                    <app-select id="type" v-model="form.fields.type" :options="['Standard', 'Recurring', 'Deadline']" />
                  </app-form-field>
                  <transition enter-active-class="transition-opacity duration-500" enter-from-class="opacity-0" enter-to-class="opacity-100" leave-active-class="transition-opacity duration-500" leave-from-class="opacity-100" leave-to-class="opacity-0">
                    <div v-if="form.fields.type != 'Standard'" class="w-8/12" :class="{ 'flex': form.fields.type == 'Recurring'}">
                      <app-form-field v-if="form.fields.type == 'Recurring'" class="ml-2 w-7/12">
                        <app-label id="recurring-type">
                          Interval
                        </app-label>
                        <app-select id="recurring-type" v-model="form.fields.recurringType" :options="['Weekly', 'Monthly']" />
                      </app-form-field>
                      <app-form-field v-if="form.fields.type == 'Recurring' && form.fields.recurringType === 'Weekly'" class="ml-2 w-5/12">
                        <app-label id="recurring-day-of-week">
                          Day of week
                        </app-label>
                        <app-select id="recurring-day-of-week" v-model="form.fields.recurringDayOfWeek" :options="weekDays" />
                      </app-form-field>
                      <app-form-field v-if="form.fields.type == 'Recurring' && form.fields.recurringType === 'Monthly'" class="ml-2 w-5/12">
                        <app-label id="recurring-day-of-month">
                          Day of month
                        </app-label>
                        <app-input id="recurring-day-of-month" v-model="form.fields.recurringDayOfMonth" type="number" min="1" max="28" />
                      </app-form-field>
                      <app-form-field v-if="form.fields.type == 'Deadline'" class="ml-2">
                        <app-label id="do-on">
                          Date
                        </app-label>
                        <app-input id="do-on" v-model="form.fields.doOn" :class="{'empty-date-input': !form.fields.doOn}" type="date" />
                      </app-form-field>
                    </div>
                  </transition>
                </div>
              </template>
              <app-form-field>
                <app-label id="title">
                  Title
                </app-label>
                <app-input id="title" v-model.trim="form.fields.title" maxlength="120" />
              </app-form-field>
              <template v-if="showAllFormInputs">
                <app-form-field v-if="aid == '2147538260'">
                  <app-label id="done-on">
                    {{ form.fields.type == "Recurring" ? 'Last completed on' : 'Completed on' }}
                  </app-label>
                  <app-input id="done-on" v-model="form.fields.doneOn" :class="{'empty-date-input': !form.fields.doneOn}" type="date" />
                </app-form-field>
                <app-form-field>
                  <app-label id="link">
                    External link
                  </app-label>
                  <app-input id="link" v-model.trim="form.fields.link" maxlength="250" placeholder="http://domain.com/page" />
                </app-form-field>
              </template>
              <div class="flex">
                <div class="flex-grow">
                  <app-button class="mt-3 transition-colors" :disabled="loading || invalidForm">
                    {{ editUuid ? "Update" : "Create" }}
                  </app-button>
                </div>
                <div v-if="!showAllFormInputs" class="self-end">
                  <button class="capitalize border border-gray-500 px-1.5 py-0.5 text-xs bg-gray-200 rounded text-gray-700 hover:border-gray-600 focus:outline-none focus:border-blue-400" @click.prevent="showAllFormInputs=true">
                    <svg xmlns="http://www.w3.org/2000/svg" class="hidden md:inline align-text-top h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                    <span class="md:ml-1 md:mr-0.5">More</span>
                  </button>
                </div>
              </div>
            </app-form>
          </template>
        </app-modal>
      </transition>
    </app-box>
  </div>
</template>

<script>
import { nextTick } from "vue";
import { mapGetters, mapState } from "vuex";
import { Util as U } from "@/util";
export default {
  name: "Tasks",
  data() {
    return {
      animate: true,
      showFilterOptions: false,
      showDoneTasks: true,
      showAllFormInputs: false,
      originalLabelUuids: [],
      activeForm: "",
      dragOver: "",
      editUuid: "",
      form: {
        fields: {
          labelUuids: [],
          projectUuid: "",
          title: "",
          link: "",
          type: "",
          recurringType: "",
          recurringDayOfWeek: "",
          recurringDayOfMonth: "",
          doOn: "",
          doneOn: "",
        },
      },
      items: [],
      projects: [],
      labels: [],
      showModal: false,
      tooltipTextNew: `Click (or use + on keyboard) to open the new task window.`,
      tooltipTextComplete: `Click to mark task as completed.`,
    };
  },
  computed: {
    ...mapGetters(["loggedIn"]),
    ...mapState(["focusUuid", "loading", "minimal", "modal"]),
    weekDays() {
      return [
        { name: "Mon.", value: 1 },
        { name: "Tue.", value: 2 },
        { name: "Wed.", value: 3 },
        { name: "Thu.", value: 4 },
        { name: "Fri.", value: 5 },
        { name: "Sat.", value: 6 },
        { name: "Sun.", value: 7 },
      ];
    },
    moveClass() {
      return this.animate ? "transition-transform duration-500" : "";
    },
    enterActiveClass() {
      return this.animate ? "transition-colors duration-slow" : "";
    },
    enterFromClass() {
      return this.animate ? "bg-yellow-200" : "";
    },
    enterToClass() {
      return this.animate ? "bg-transparent" : "";
    },
    leaveActiveClass() {
      return this.animate ? "transition-opacity duration-150" : "";
    },
    leaveFromClass() {
      return this.animate ? "opacity-100" : "";
    },
    leaveToClass() {
      return this.animate ? "opacity-0" : "";
    },
    cItems() {
      const nowString = U.localDate();
      const nowNum = parseInt(nowString.replace(/-/g, ""));
      const nowYear = parseInt(nowString.substring(0, 4));
      const nowMonth = parseInt(nowString.substring(5, 7));
      const nowMonthDay = parseInt(nowString.substring(8, 10));
      const nowWeek = U.week(nowYear, nowMonth, nowMonthDay);
      const nowWeekDay = new Date(nowString + "T00:00:00").getDay() || 7;
      return [...this.items]
        .map((i) => {
          i.projectName = this.projects.find(
            (p) => p.uuid === i.projectUuid
          ).name;
          i.done = i.doneOn === nowString;
          i.linkTitle = i.link ? U.domainFromUrl(i.link) : "";
          if (i.recurringDayOfWeek || i.recurringDayOfMonth) {
            i.recurringText = i.recurringDayOfWeek
              ? `Weekly (${U.dayOfWeek(i.recurringDayOfWeek)})`
              : `Monthly (${U.daySuffix(parseInt(i.recurringDayOfMonth))})`;
          } else {
            i.recurringText = "";
          }
          i.doOnText = "";
          i.draggable = !i.done;
          i.orange = !i.done && (i.recurringDayOfWeek || i.recurringDayOfMonth);
          if (!i.done && i.doOn) {
            const doOnNum = parseInt(i.doOn.replace(/-/g, ""));
            i.red = nowNum >= doOnNum;
            if (nowNum === doOnNum) {
              i.doOnText = "Due Today";
            } else if (nowNum - 1 === doOnNum) {
              i.doOnText = "Due Yesterday";
            } else if (nowNum + 1 === doOnNum) {
              i.doOnText = "Due Tomorrow";
            } else if (nowNum > doOnNum) {
              i.doOnText = `Overdue (${U.formatDate(i.doOn)})`;
            } else {
              i.doOnText = `Due ${U.formatDate(i.doOn)}`;
            }
          }
          i.options = [];
          i.options.push("Edit");
          if (!i.done) {
            i.options.push(i.labels.length ? "Edit labels" : "Add labels");
            if (!i.recurringDayOfWeek && !i.recurringDayOfMonth && !i.doOn) {
              i.options.push("Move to backlog");
            }
          }
          i.options.push("Delete");
          return i;
        })
        .filter((i) => {
          const doneYear = parseInt(i.doneOn?.substring(0, 4));
          const doneMonth = parseInt(i.doneOn?.substring(5, 7));
          let doneMonthDiff = i.doneOn ? nowMonth - doneMonth : 0;
          if (doneMonthDiff < 0) {
            doneMonthDiff = doneMonthDiff + doneMonth;
          }
          const doneDayOfMonth = parseInt(i.doneOn?.substring(8, 10));
          const doneDayOfWeek = new Date(i.doneOn + "T00:00:00").getDay() || 7;
          const doneWeek = U.week(doneYear, doneMonth, doneDayOfMonth);
          let doneWeekDiff = i.doneOn ? nowWeek - doneWeek : 0;
          if (doneWeekDiff < 0) {
            doneWeekDiff = doneWeekDiff + doneWeek;
          }
          const doneThisMonth = doneMonth === nowMonth;
          const doneThisWeek = doneWeek === nowWeek;
          const doneFilter = !i.doneOn || i.doneOn === nowString;
          const recurringWeeklyFilter =
            !i.doneOn ||
            i.doneOn === nowString ||
            doneWeekDiff > 1 ||
            (doneThisWeek &&
              i.recurringDayOfWeek > doneDayOfWeek &&
              i.recurringDayOfWeek <= nowWeekDay) ||
            (!doneThisWeek &&
              (i.recurringDayOfWeek > doneDayOfWeek ||
                nowWeekDay >= i.recurringDayOfWeek));
          const recurringMonthlyFilter =
            !i.doneOn ||
            i.doneOn === nowString ||
            doneMonthDiff > 1 ||
            (doneThisMonth &&
              i.recurringDayOfMonth > doneDayOfMonth &&
              i.recurringDayOfMonth <= nowMonthDay) ||
            (!doneThisMonth &&
              (i.recurringDayOfMonth > doneDayOfMonth ||
                nowMonthDay >= i.recurringDayOfMonth));
          return (
            (i.projectUuid === this.focusUuid || this.focusUuid === "") &&
            !i.backlog &&
            ((i.recurringDayOfWeek && recurringWeeklyFilter) ||
              (i.recurringDayOfMonth && recurringMonthlyFilter) ||
              (!i.recurringDayOfWeek && !i.recurringDayOfMonth && doneFilter))
          );
        })
        .sort((a, b) => {
          if (a.done && b.done) {
            const aTitle = a.title.toLowerCase();
            const bTitle = b.title.toLowerCase();
            return aTitle < bTitle ? -1 : aTitle > bTitle ? 1 : 0;
          } else {
            return b.done - a.done || b.ms - a.ms;
          }
        });
    },
    cIncompleteItems() {
      return this.cItems.filter((i) => !i.done);
    },
    cProjects() {
      return [...this.projects]
        .sort((a, b) => {
          const aName = a.name.toLowerCase();
          const bName = b.name.toLowerCase();
          return aName < bName ? -1 : aName > bName ? 1 : 0;
        })
        .filter(
          (p) =>
            p.active && (p.uuid === this.focusUuid || this.focusUuid === "")
        )
        .map((p) => ({ name: p.name, value: p.uuid }));
    },
    cLabels() {
      return [...this.labels]
        .sort((a, b) => {
          const aName = a.name.toLowerCase();
          const bName = b.name.toLowerCase();
          return aName < bName ? -1 : aName > bName ? 1 : 0;
        })
        .map((l) => ({ name: l.name, value: l.uuid }));
    },
    done() {
      return this.cItems.reduce((acc, cur) => (cur.done ? ++acc : acc), 0);
    },
    todo() {
      return this.cItems.reduce((acc, cur) => (!cur.done ? ++acc : acc), 0);
    },
    invalidForm() {
      const {
        projectUuid,
        title,
        link,
        type,
        recurringType,
        recurringDayOfWeek,
        recurringDayOfMonth,
        doOn,
      } = this.form.fields;
      if (projectUuid === "" || title === "") {
        return true;
      }
      if (link && !/http[s]?:\/\/.+\..+/.test(link)) {
        return true;
      }
      if (type === "Recurring") {
        if (recurringType === "") {
          return true;
        } else if (recurringType === "Weekly") {
          if (recurringDayOfWeek === "") {
            return true;
          }
        } else if (recurringType === "Monthly") {
          if (
            recurringDayOfMonth === "" ||
            recurringDayOfMonth < 1 ||
            recurringDayOfMonth > 28
          ) {
            return true;
          }
        }
      } else if (type === "Deadline" && doOn === "") {
        return true;
      }
      return false;
    },
    draggableItemCount() {
      return this.cItems.filter((i) => i.draggable).length;
    },
  },
  async created() {
    if (U.redirect(this.loggedIn)) return this.$router.push("/");
    this.aid = window.localStorage.getItem("aid");
    await this.getData();
  },
  mounted() {
    document.addEventListener("keyup", this.keyUp, false);
  },
  unmounted() {
    document.removeEventListener("keyup", this.keyUp, false);
  },
  methods: {
    async handleDoneTasksToggle() {
      this.animate = false;
      this.showDoneTasks = !this.showDoneTasks;
      await nextTick();
      this.animate = true;
    },
    handleFilterClick() {
      this.showFilterOptions = !this.showFilterOptions;
    },
    keyUp(event) {
      if (!this.modal && event.key == "+") {
        this.openModal();
      }
    },
    async getData() {
      const [{ items }, { projects }, { labels }] = await U.apiAll([
        { url: "api/items?backlog=false&recent=true&deep=true" },
        { url: "api/projects" },
        { url: "api/labels" },
      ]);
      this.items = items;
      this.projects = projects;
      this.labels = labels;
    },
    tooltipData(item) {
      return {
        show: !this.minimal && !item.done,
        position: "top",
      };
    },
    drop(uuid, e) {
      this.dragOver = "";
      const dragUuid = e.dataTransfer.getData("text");
      if (dragUuid.length !== 36 || dragUuid === uuid) return;
      const dropIndex = this.cIncompleteItems.findIndex((x) => x.uuid === uuid);
      if (dropIndex === -1) return;
      const dropItem = this.cIncompleteItems[dropIndex];
      if (!dropItem.draggable) return;
      const dragIndex = this.cIncompleteItems.findIndex(
        (x) => x.uuid === dragUuid
      );
      const dragItem = this.cIncompleteItems[dragIndex];
      let ms;
      if (dropItem.ms > dragItem.ms) {
        if (dropIndex > 0) {
          const diff = dropItem.ms - this.cIncompleteItems[dropIndex - 1].ms;
          ms = Math.round(dropItem.ms - diff / 2);
        } else {
          ms = Math.round(dropItem.ms + 10000);
        }
      } else {
        if (dropIndex + 1 < this.cIncompleteItems.length) {
          const diff = dropItem.ms - this.cIncompleteItems[dropIndex + 1].ms;
          ms = Math.round(dropItem.ms - diff / 2);
        } else {
          ms = Math.round(dropItem.ms - 10000);
          if (ms < 1) {
            ms = 1;
          }
        }
      }
      this.items = U.patch("item", this.items, dragUuid, { ms });
    },
    dragStart(uuid, e) {
      e.dataTransfer.setData("text", uuid);
    },
    check(uuid, checked) {
      const doneOn = checked ? U.localDate() : null;
      this.$store.commit("addTooltip", U.hash(this.tooltipTextComplete));
      this.items = U.patch("item", this.items, uuid, { doneOn });
    },
    handleChoice(item, option) {
      if (option === "Edit") {
        this.openModal(item.uuid);
      } else if (option === "Add labels" || option === "Edit labels") {
        this.openModal(item.uuid, "labels");
      } else if (option === "Move to backlog") {
        const backlog = true;
        this.items = U.patch("item", this.items, item.uuid, { backlog });
      } else if (option === "Delete") {
        this.items = U.delete("item", this.items, item.uuid);
      }
    },
    openModal(uuid, form = "") {
      this.editUuid = uuid || "";
      this.activeForm = form;
      this.showAllFormInputs = this.editUuid;
      if (form === "labels") {
        const item = this.items.find((i) => i.uuid === this.editUuid);
        this.form.fields.labelUuids = item.labels.map((x) => x.uuid);
        this.originalLabelUuids = this.form.fields.labelUuids;
      } else {
        if (!this.editUuid) {
          this.$store.commit("addTooltip", U.hash(this.tooltipTextNew));
        }
        for (const field in this.form.fields) {
          let defaultValue, item;
          if (this.editUuid) {
            item = { ...this.cItems.find((i) => i.uuid === this.editUuid) };
            if (field === "type") {
              if (item.recurringDayOfWeek || item.recurringDayOfMonth) {
                item.type = "Recurring";
              } else if (item.doOn) {
                item.type = "Deadline";
              } else {
                item.type = "Standard";
              }
            } else if (field === "recurringType" && item.recurringDayOfWeek) {
              item.recurringType = "Weekly";
            } else if (field === "recurringType" && item.recurringDayOfMonth) {
              item.recurringType = "Monthly";
            }
          } else {
            defaultValue = "";
            if (field === "projectUuid") {
              defaultValue =
                this.cProjects.length === 1
                  ? this.cProjects[0].value
                  : window.localStorage.getItem("lastUuid") || "";
            } else if (field === "type") {
              defaultValue = "Standard";
            }
          }
          this.form.fields[field] = item ? item[field] : defaultValue;
        }
      }
      this.showModal = true;
    },
    submitForm() {
      if (this.invalidForm) return;
      let {
        projectUuid,
        title,
        link,
        doneOn,
        type,
        recurringType,
        recurringDayOfWeek,
        recurringDayOfMonth,
        doOn,
      } = this.form.fields;
      const obj = { projectUuid, title, link };
      obj.recurringDayOfWeek = "";
      obj.recurringDayOfMonth = "";
      obj.doOn = "";
      if (type === "Recurring") {
        if (recurringType === "Weekly") {
          obj.recurringDayOfWeek = recurringDayOfWeek;
        }
        if (recurringType === "Monthly") {
          obj.recurringDayOfMonth = recurringDayOfMonth;
        }
      } else if (type === "Deadline") {
        obj.doOn = doOn;
      }
      if (this.editUuid) {
        const uuid = this.editUuid;
        if (doneOn) {
          obj.doneOn = doneOn;
        }
        this.items = U.patch("item", this.items, uuid, obj);
      } else {
        obj.backlog = false;
        obj.addedOn = U.localDate();
        obj.doneOn = null;
        obj.details = null;
        obj.ms = Date.now();
        obj.labels = [];
        this.items = U.post("item", this.items, obj);
        U.scrollToLastAdded();
        window.localStorage.setItem("lastUuid", obj.projectUuid);
      }
      this.closeModal();
    },
    submitLabelsForm() {
      const foundItem = this.items.find((x) => x.uuid == this.editUuid);
      let { labelUuids } = this.form.fields;
      labelUuids.forEach((labelUuid) => {
        if (!this.originalLabelUuids.includes(labelUuid)) {
          const itemLabel = {
            uuid: U.uuid(),
            itemUuid: this.editUuid,
            labelUuid,
          };
          U.post("itemLabel", null, itemLabel);
          const foundLabel = this.labels.find((x) => x.uuid == labelUuid);
          foundLabel.itemLabelUuid = itemLabel.uuid;
          foundItem.labels.push(foundLabel);
        }
      });
      this.originalLabelUuids.forEach((originalLabelUuid) => {
        if (!labelUuids.includes(originalLabelUuid)) {
          const found = foundItem.labels.find(
            (x) => x.uuid == originalLabelUuid
          );
          U.delete("itemLabel", null, found.itemLabelUuid);
          const index = foundItem.labels.findIndex(
            (x) => x.uuid === originalLabelUuid
          );
          foundItem.labels.splice(index, 1);
        }
      });
      this.closeModal();
    },
    closeModal() {
      this.showModal = false;
    },
    saveDetails(uuid, details) {
      this.items = U.patch("item", this.items, uuid, { details });
    },
  },
};
</script>
